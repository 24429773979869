import { FC, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { flatten, isEmpty, pluck } from "ramda";

import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import Table from "common/components/atoms/Table/Table";
import { P, Ui } from "common/components/atoms/Typography";
import { ChevronRightSmallIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DocumentsContext, { DocumentFilters } from "../../DocumentsContext";
import classes from "../CommonStyles.module.scss";
import EmptyDocumentsRow from "../EmptyDocumentsRow";
import ProgramDocumentsTableBody from "./ProgramDocumentsTable/ProgramDocumentsTableBody";

const t = createTranslation(TranslationNS.pages, "company.documents");

const ProgramDocuments: FC = () => {
  const { filteredDocuments, searchValue, filterValue } = DocumentsContext.useStoreState((state) => state);

  const ref = useRef<HTMLDivElement>(null);
  const collapseRef = useRef<any>(null);
  const [hasClicked, setHasClicked] = useState<boolean>(false);

  const DocumentsHeader = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
      <div className={classes.header} onClick={onClick} ref={ref}>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Ui.xl bold>{t("programDocuments.title")}</Ui.xl>
            <div className={classes["documents-rounded-container"]}>
              <Ui.xs>
                {flatten(pluck("documents", flatten(pluck("agreements", flatten(filteredDocuments.programDocuments)))))
                  .length + flatten(pluck("programDocuments", filteredDocuments.programDocuments)).length}
              </Ui.xs>
            </div>
          </div>

          <Ui.xs style={{ color: scssVariables.foregroundLow }}>{t("programDocuments.description")}</Ui.xs>
        </div>

        <div data-testid="company-documents-open-table-test-id" className="d-flex align-items-center">
          <ChevronRightSmallIcon
            className={cn(classes.icon, {
              [classes.open]: Boolean(activeEventKey),
            })}
          />
        </div>
      </div>
    ),
    [filteredDocuments]
  );

  useEffect(() => {
    if (!searchValue.trim()) {
      setHasClicked(false);

      if (collapseRef?.current?.state?.status === "entered") {
        ref.current?.click();
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (filteredDocuments.programDocuments.length && searchValue.trim() && !hasClicked) {
      ref.current?.click();
      setHasClicked(true);
    }
  }, [filteredDocuments.programDocuments.length, hasClicked, searchValue]);

  if (isEmpty(filteredDocuments.programDocuments) && (searchValue || filterValue !== DocumentFilters.showAll)) {
    return null;
  }

  return (
    <CollapsibleContainer className="my-3" Header={DocumentsHeader} collapseRef={collapseRef}>
      <div className={cn("d-flex flex-column", classes["documents-content"])}>
        {filteredDocuments.programDocuments.length ? (
          <Table
            headComponent={null}
            bodyComponent={<ProgramDocumentsTableBody data={filteredDocuments.programDocuments} />}
            className={cn(classes.table)}
          />
        ) : (
          <div className={classes.empty}>
            <P.s>{searchValue?.trim() ? t("noResults") : t("emptyPlaceholder")}</P.s>
          </div>
        )}
      </div>
    </CollapsibleContainer>
  );
};

export default ProgramDocuments;
