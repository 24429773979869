import { FC, useCallback } from "react";
import { isEmpty } from "ramda";

import InviteStakeholderModal from "../../stakeholders-management/components/invite-stakeholder-modal/invite-stakeholder-modal";
import { CapTableVariantsType } from "../CapTable";
import classes from "../CapTable.module.scss";
import CapTableActions from "../common/actions/CapTableActions";
import EmptyTable from "../common/empty/empty-table";
import CapTableStore from "../store";
import Charts from "./Charts/Charts";
import InvitedStakeholdersIndicator from "./InvitedStakeholdersIndicator/InvitedStakeholdersIndicator";
import Table from "./Table/Table";

type Props = {
  capTableVariant: CapTableVariantsType;
  setCapTableVariant: (val: CapTableVariantsType) => void;
};

const Actual: FC<Props> = ({ capTableVariant, setCapTableVariant }) => {
  const capTableDataStore = CapTableStore.useStoreState((state) => state.actualData.capTable);
  const setSelectedRowData = CapTableStore.useStoreActions((actions) => actions.setSelectedRowData);

  const handleCloseInviteModal = useCallback(() => {
    setSelectedRowData(undefined);
  }, [setSelectedRowData]);

  return (
    <>
      <Charts className={classes.charts} />

      <InvitedStakeholdersIndicator className="mb-3" />

      {isEmpty(capTableDataStore) ? null : (
        <CapTableActions type={capTableVariant} setCapTableVariant={setCapTableVariant} className="mb-4" />
      )}

      <div className={classes["table-container"]}>
        {isEmpty(capTableDataStore) ? <EmptyTable /> : <Table className={classes["scrollable-table"]} />}
      </div>

      <InviteStakeholderModal onClose={handleCloseInviteModal} />
    </>
  );
};

export default Actual;
