import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import * as R from "ramda";

import { Ui } from "common/components/atoms";
import { CapTableGroupByOptions } from "common/enums/enum";
import { ShareClassesIcon, UsersIcon } from "common/icons/svg";
import { sortCaptableData } from "common/utils/functions";

import { DilutedCapTableServiceGetResponse } from "../../CapTable.types";
import CapTableStore from "../../store";
import Row from "./Row";
import { FullyDilutedCapTableSortValue } from "./Table";
import classes from "./Table.module.scss";

const Body: FC<{ sortedValue: FullyDilutedCapTableSortValue }> = ({ sortedValue }) => {
  const [classesState, setClassesState] = useState<{ [key: string]: boolean }>({});
  const [instrumentState, setInstrumentState] = useState<{ [key: string]: boolean }>({});
  const [relationshipsState, setRelationshipsState] = useState<{
    [key: string]: boolean;
  }>({});

  const capTableDataStore = CapTableStore.useStoreState((state) => state.dilutedData.capTable);
  const capTableByShareholder = CapTableStore.useStoreState((state) => state.dilutedData.capTableByShareHolder);
  const searchValue = CapTableStore.useStoreState((state) => state.searchValue);
  const selectedGroupStore = CapTableStore.useStoreState((state) => state.selectedDilutedGroup);

  const filteredData = useMemo(() => {
    if (R.isEmpty(searchValue)) {
      return capTableDataStore;
    }

    return capTableDataStore.filter(
      (el) =>
        el.stakeholderName.toLowerCase().includes(searchValue.toLowerCase()) ||
        el?.representedBy?.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.shareClasses.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase())).length > 0
    );
  }, [capTableDataStore, searchValue]);

  const filteredShareholderData = useMemo(() => {
    if (R.isEmpty(searchValue)) {
      return capTableByShareholder;
    }

    return capTableByShareholder.filter(
      (el) =>
        el.stakeholderName.toLowerCase().includes(searchValue.toLowerCase()) ||
        el?.representedBy?.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.shareClasses.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase())).length > 0
    );
  }, [capTableByShareholder, searchValue]);

  const sortedData = useMemo(() => {
    return sortCaptableData({
      data: filteredData,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, filteredData]);

  const sortedShareholderData = useMemo(() => {
    return sortCaptableData({
      data: filteredShareholderData,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, filteredShareholderData]);

  const isDefaultValueSelected = CapTableGroupByOptions.default;
  const isShareholderValueSelected = selectedGroupStore === CapTableGroupByOptions.shareholder;
  const isRelationshipValueSelected = selectedGroupStore === CapTableGroupByOptions.relationship;
  const isClassesValueSelected = selectedGroupStore === CapTableGroupByOptions.shareClass;
  const isInstrumentValueSelected = selectedGroupStore === CapTableGroupByOptions.instrument;

  const isEmpty = useMemo(() => !R.isNil(sortedData) && R.isEmpty(sortedData), [sortedData]);

  const allRelationships = useMemo(
    () =>
      filteredData.reduce((prev, { relationship: currRelationship }) => {
        prev[currRelationship] = sortCaptableData({
          data: filteredData.filter(({ relationship }) => relationship === currRelationship),
          sortedField: sortedValue.field,
          sortType: sortedValue.type,
        });

        return prev;
      }, {} as { [relationship: string]: DilutedCapTableServiceGetResponse["capTable"] }),
    [filteredData, sortedValue]
  );

  const allClasses = useMemo(() => {
    const shareClassesGroup = R.uniq(R.flatten(R.pluck("shareClasses", filteredData))).reduce((acc, curr) => {
      acc[curr] = sortCaptableData({
        data: filteredData.filter((item) => item.shareClasses.includes(curr)),
        sortedField: sortedValue.field,
        sortType: sortedValue.type,
      });

      return acc;
    }, {} as { [className: string]: DilutedCapTableServiceGetResponse["capTable"] });

    const noShareClass = filteredData.filter((item) => item.shareClasses.length === 0);
    if (noShareClass.length > 0) {
      shareClassesGroup["No Share Class"] = noShareClass;
    }

    return shareClassesGroup;
  }, [filteredData, sortedValue]);

  const allInstruments = useMemo(
    () =>
      filteredData.reduce((prev, { instrument: currRelationship }) => {
        const currentInstrument = currRelationship || "Shares";
        prev[currentInstrument] = sortCaptableData({
          data: filteredData.filter(({ instrument }) => instrument === currRelationship),
          sortedField: sortedValue.field,
          sortType: sortedValue.type,
        });

        return prev;
      }, {} as { [relationship: string]: DilutedCapTableServiceGetResponse["capTable"] }),
    [filteredData, sortedValue]
  );

  const renderEmptyTable = useCallback(() => {
    return Array.from({ length: 5 }, (el) => el).map((el, index) => {
      return (
        <tr key={index} className={classes["tr-empty"]}>
          <td>
            <div className="d-flex">
              <div
                className={classNames(classes["blank-item"], "me-1", {
                  [classes["circle"]]: true,
                })}
              />

              <div className={classes["blank-item"]} style={{ flexBasis: "100%" }} />
            </div>
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
          <td>
            <div className={classes["blank-item"]} />
          </td>
        </tr>
      );
    });
  }, []);

  const renderDefaultTable = useCallback(
    () => (
      <AnimatePresence>
        {sortedData.map((item) => (
          <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
        ))}
      </AnimatePresence>
    ),
    [sortedData]
  );

  const renderShareholdersTable = useCallback(
    () => (
      <AnimatePresence>
        {sortedShareholderData.map((item) => (
          <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
        ))}
      </AnimatePresence>
    ),
    [sortedShareholderData]
  );

  const renderRelationshipTable = useCallback(
    () =>
      Object.keys(allRelationships).map((el, indexF) => (
        <Fragment key={indexF}>
          <AnimatePresence>
            <motion.tr
              className={classes.tableBorder}
              key="relationship-key"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => {
                setRelationshipsState((state) => ({
                  ...state,
                  [el]: !state[el],
                }));
              }}
            >
              <td colSpan={8}>
                <div className="d-flex align-items-center">
                  <UsersIcon />
                  <Ui.s className="ms-2 fw-500">{el}</Ui.s>
                </div>
              </td>
            </motion.tr>

            <AnimatePresence>
              {relationshipsState[el] &&
                allRelationships[el].map((item) => (
                  <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
                ))}
            </AnimatePresence>
          </AnimatePresence>
        </Fragment>
      )),
    [allRelationships, relationshipsState]
  );

  const renderClassesTable = useCallback(
    () =>
      Object.keys(allClasses).map((el, indexF) => (
        <Fragment key={indexF}>
          <AnimatePresence>
            <motion.tr
              className={classes.tableBorder}
              key="classes-key"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => {
                setClassesState((state) => ({ ...state, [el]: !state[el] }));
              }}
            >
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <ShareClassesIcon />
                  <Ui.s className="ms-2 fw-500">{el}</Ui.s>
                </div>
              </td>
            </motion.tr>

            <AnimatePresence>
              {classesState[el] &&
                allClasses[el].map((item) => (
                  <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
                ))}
            </AnimatePresence>
          </AnimatePresence>
        </Fragment>
      )),
    [allClasses, classesState]
  );

  const renderInstrumentTable = useCallback(
    () =>
      Object.keys(allInstruments).map((el, indexF) => (
        <Fragment key={indexF}>
          <AnimatePresence>
            <motion.tr
              className={classes.tableBorder}
              key="classes-key"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => {
                setInstrumentState((state) => ({ ...state, [el]: !state[el] }));
              }}
            >
              <td colSpan={8}>
                <div className="d-flex align-items-center">
                  <ShareClassesIcon />
                  <Ui.s className="ms-2 fw-500">{el}</Ui.s>
                </div>
              </td>
            </motion.tr>

            <AnimatePresence>
              {instrumentState[el] &&
                allInstruments[el].map((item) => (
                  <Row key={`${item.stakeholderId} ${item.shareClasses.join("")}`} data={item} />
                ))}
            </AnimatePresence>
          </AnimatePresence>
        </Fragment>
      )),
    [allInstruments, instrumentState]
  );

  useEffect(() => {
    setRelationshipsState(
      Object.keys(allRelationships).reduce((acc, el) => {
        acc[el] = true;

        return acc;
      }, {} as { [key: string]: boolean })
    );

    setClassesState(
      Object.keys(allClasses).reduce((acc, el) => {
        acc[el] = true;

        return acc;
      }, {} as { [key: string]: boolean })
    );
    setInstrumentState(
      Object.keys(allInstruments).reduce((acc, el) => {
        acc[el] = true;

        return acc;
      }, {} as { [key: string]: boolean })
    );
  }, [allClasses, allInstruments, allRelationships]);

  if (isEmpty) {
    return <tbody>{renderEmptyTable()}</tbody>;
  }

  return (
    <tbody>
      {isDefaultValueSelected && renderDefaultTable()}
      {isShareholderValueSelected && renderShareholdersTable()}
      {isRelationshipValueSelected && renderRelationshipTable()}
      {isClassesValueSelected && renderClassesTable()}
      {isInstrumentValueSelected && renderInstrumentTable()}
    </tbody>
  );
};

export default Body;
