import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AccordionContext, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { defaultTo } from "ramda";

import { P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowUpIcon, CommonFileAddIcon, InformationCircleIcon, TotalValueIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../transactions-service";
import { CapitalIncreaseGetDTO, Transaction, TransactionCategoryIds, TransactionStatusIds } from "../../../../types";
import TransactionsList from "../../transactions-list";

type PropsTypes = {
  bundleId: number;
  bundleStatusId: TransactionStatusIds;
  eventKey: string;
  contextMenuHandlers: {
    handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
    handleOpenConfirmTransaction?: (transaction: Transaction) => void;
    handleOpenDeleteTransaction?: (transactionId: number) => void;
    handleOpenRollbackTransaction?: (transaction: Transaction) => void;
  };
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const CapitalIncreaseBody: FC<PropsTypes> = ({ bundleId, bundleStatusId, eventKey, contextMenuHandlers }) => {
  const { companyId = "0" } = useParams();
  const fNumber = useFormatNumbers();
  const { activeEventKey } = useContext(AccordionContext);
  const { getCapitalIncrease } = useTransactionsService(companyId);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [capitalIncreaseData, setCapitalIncreaseData] = useState<CapitalIncreaseGetDTO>();

  const getCapitalIncreaseRequest = useCallback(async () => {
    setIsLoading(true);
    setCapitalIncreaseData(await getCapitalIncrease(bundleId));

    setIsLoading(false);
  }, [bundleId, getCapitalIncrease]);

  const totalAmount = useMemo(() => {
    if (capitalIncreaseData?.totalIncrease && capitalIncreaseData?.sharePrice) {
      return (capitalIncreaseData.totalIncrease * capitalIncreaseData.sharePrice).toFixed(8);
    }

    return 0;
  }, [capitalIncreaseData]);

  const totalAmountOfSharesInsideTransactions = useMemo(
    () =>
      defaultTo([] as Transaction[], capitalIncreaseData?.transactions).reduce(
        (acc: number, curr: Transaction) => acc + curr.numberOfShares,
        0
      ),
    [capitalIncreaseData?.transactions]
  );

  useEffect(() => {
    // check if accordion body is opened
    if (eventKey === activeEventKey) {
      getCapitalIncreaseRequest();
    }
  }, [activeEventKey, eventKey, getCapitalIncrease, getCapitalIncreaseRequest, bundleStatusId]);

  return (
    <div>
      <div
        className="d-flex justify-content-evenly pb-4 mb-4"
        style={{ borderBottom: `1px solid ${scssVariables.strokeHigh}` }}
      >
        <div className="d-flex flex-fill">
          <CommonFileAddIcon color={scssVariables.foregroundLow} width={40} height={40} />
          <div className="ms-2">
            <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseData?.totalIncrease, "amount")}</Ui.xxl>
            <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
          </div>
        </div>

        <div className="d-flex flex-fill">
          <ArrowUpIcon width={40} height={40} color={scssVariables.foregroundLow} />
          <div className="ms-2">
            <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(totalAmount, "amount")}</Ui.xxl>
            <Ui.xs className="text-medium">{t("totalAmount")}</Ui.xs>
          </div>
        </div>

        <div className="d-flex flex-fill">
          <TotalValueIcon width={40} height={40} color={scssVariables.foregroundLow} />
          <div className="ms-2">
            <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseData?.sharePrice, "sharePrice")}</Ui.xxl>
            <Ui.xs className="text-medium">{t("sharePrice")}</Ui.xs>
          </div>
        </div>
      </div>
      {capitalIncreaseData?.description && (
        <div>
          <Ui.m bold className="text-medium mb-2">
            {t("description")}
          </Ui.m>
          <P.s className="text-medium mb-4">{capitalIncreaseData.description}</P.s>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <Ui.m bold className="text-medium">
          {t("transactions")}
        </Ui.m>

        {capitalIncreaseData?.totalIncrease === totalAmountOfSharesInsideTransactions ? null : (
          <Tag variant="draft">
            <WarningIcon className="me-1" />
            {defaultTo(0, capitalIncreaseData?.totalIncrease) > totalAmountOfSharesInsideTransactions
              ? t("sharesRemaining", {
                  shares: fNumber(
                    defaultTo(0, capitalIncreaseData?.totalIncrease) - totalAmountOfSharesInsideTransactions,
                    "amount"
                  ),
                })
              : t("sharesTooMany", {
                  shares: fNumber(
                    totalAmountOfSharesInsideTransactions - defaultTo(0, capitalIncreaseData?.totalIncrease),
                    "amount"
                  ),
                })}
          </Tag>
        )}
      </div>
      {(capitalIncreaseData?.transactions?.length || -1) > 0 ? (
        <TransactionsList
          isMini
          transactions={capitalIncreaseData?.transactions || []}
          bundleView
          itemIdToOpen={null}
          {...contextMenuHandlers}
        />
      ) : (
        <div className="border-1 rounded-2 py-2 px-3" style={{ backgroundColor: scssVariables.surface1 }}>
          <Ui.l bold className="mb-3">
            {t("emptyTitle")}
          </Ui.l>
          <div className="d-flex align-items-center text-low">
            <InformationCircleIcon />
            <Ui.xs className="ms-2">{t("emptyDescription")}</Ui.xs>
          </div>
        </div>
      )}
    </div>
  );
};

export default CapitalIncreaseBody;
