import { FC, useMemo, useState } from "react";
import cn from "classnames";

import { Table, Tag, Ui } from "common/components/atoms";
import { SingleShareClassData } from "common/components/organisms/stakeholder-details/types";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import classes from "../stakeholder-details.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareClasssesTable");

const Head = ({
  sortingValue,
  setSortingValue,
}: {
  sortingValue: sortingParams;
  setSortingValue: (e: sortingParams) => void;
}) => {
  const rows = useMemo(
    () => [
      {
        key: "shareclass",
        value: t("head.shareClass"),
      },
      {
        key: "shares",
        value: t("head.shares"),
      },

      {
        key: "ownership",
        value: t("head.ownership"),
      },
      {
        key: "sharenumbers",
        value: t("head.sharesNumbers"),
      },
    ],
    []
  );

  return (
    <thead className={classes["head-row"]}>
      <tr>
        {rows.map((el, index) => (
          <th
            key={el.key}
            className={cn(
              {
                "cursor-pointer": el.key === "shares",
              },
              classes["head-item"]
            )}
            onClick={
              el.key === "shares"
                ? () => {
                    sortingValue === sortingParams.inc
                      ? setSortingValue(sortingParams.desc)
                      : setSortingValue(sortingParams.inc);
                  }
                : undefined
            }
          >
            <div className={"d-flex align-items-center fw-500"}>
              <Ui.xs className={cn({ "ms-auto": index === 1 || index === 2 })}>{el.value}</Ui.xs>

              {el.key === "shares" ? (
                <span className={cn(classes.sort)}>
                  <FilledArrowDownIcon
                    style={{
                      transition: "all 0.2s",
                      transform: sortingValue === sortingParams.inc ? "rotate(0deg)" : "rotate(180deg)",
                    }}
                  />
                </span>
              ) : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Body: FC<{ data: SingleShareClassData[] }> = ({ data }) => {
  const fNumber = useFormatNumbers();

  return (
    <tbody>
      {data?.length ? (
        data.map((el) => (
          <tr key={el.shareClassId} className={cn(classes.tableBorder, classes["no-border"])}>
            <td>
              <Ui.s>{el.shareClassName}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{fNumber(el.shares, "amount")}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{fNumber(el.ownership, "percent")}</Ui.s>
            </td>

            <td>
              <div className="d-flex flex-wrap" style={{ gap: 12 }}>
                {el.shareNumbers.map((el) => (
                  <Tag key={el} variant="closed" className="me-1">
                    {el}
                  </Tag>
                ))}
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr className={cn(classes.tableBorder, classes["no-border"])} style={{ height: 80 }}>
          <td width="100%" colSpan={10}>
            <Ui.s color="foregroundLow" className="text-start">
              {t("empty")}
            </Ui.s>
          </td>
        </tr>
      )}
    </tbody>
  );
};

const ShareClassesTable: FC<{ data: SingleShareClassData[]; className: string }> = ({ data, className }) => {
  const [sortingValue, setSortingValue] = useState<sortingParams>(sortingParams.desc);

  const sortedData = useMemo(
    () => data.sort((a, b) => (sortingValue === sortingParams.inc ? a.shares - b.shares : b.shares - a.shares)),
    [data, sortingValue]
  );

  return (
    <Table
      className={cn(classes.table, classes.borderBottom, className)}
      headComponent={<Head sortingValue={sortingValue} setSortingValue={setSortingValue} />}
      bodyComponent={<Body data={sortedData} />}
    />
  );
};

export default ShareClassesTable;
