import React, { FC, useMemo } from "react";
import cn from "classnames";
import { defaultTo, isNil } from "ramda";

import { H, LinkHelpText, NewAvatar, Tooltip, Ui } from "common/components/atoms";
import {
  ActiveUserBudgeIcon,
  CalendarIcon,
  CompanyBuildingIcon,
  CopyNewIcon,
  EmailIcon,
  MailboxIcon,
  PhoneIcon,
  PinLocationIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../stakeholder-details.context";
import classes from "../stakeholder-details.module.scss";
import { StakeholderInvitationStatus } from "../types";
import InviteButton from "./InviteButton";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.details");

const Details: FC<{ editButtonPress?: (id?: number) => void }> = ({ editButtonPress }) => {
  const relationships = useStoreState((state) => state.common?.dropdowns)?.relationshipTypes;

  const details = StakeholderDetailsContext.useStoreState((state) => state.details);
  const closeFunction = StakeholderDetailsContext.useStoreState((state) => state.closeFunction);
  const isCompany = Boolean(details?.isCompanyOwned);

  const currentRelationship = useMemo(
    () => relationships?.find((el) => el.id === details?.relationshipTypeId),
    [details?.relationshipTypeId, relationships]
  );

  return (
    <div className={cn(classes["top-container"], "px-4")}>
      <div className={classes.section}>
        <NewAvatar
          company={isCompany}
          imageUrl={details?.avatarFilePath}
          initials={details?.initials}
          className={classes.image}
        />

        <div className="d-flex align-items-center mt-2 mb-1">
          <H.s>{isCompany ? details?.companyName : `${details?.firstName} ${details?.lastName} `}</H.s>
          {details?.invitationStatus === StakeholderInvitationStatus.active && (
            <Tooltip popupContent={<div>{t("invitation.registeredUser")}</div>}>
              <ActiveUserBudgeIcon fontSize={20} className="ms-1" />
            </Tooltip>
          )}
        </div>

        <Ui.m className="mb-2" style={{ color: scssVariables.foregroundLow }}>
          {currentRelationship?.name}
        </Ui.m>

        {!isNil(details) ? (
          <InviteButton
            editButtonPress={
              editButtonPress
                ? () => {
                    editButtonPress?.(details.stakeholderId);
                  }
                : undefined
            }
            status={details.invitationStatus}
            stakeholder={{
              stakeholderId: details.stakeholderId,
              firstName: details.firstName,
              lastName: details.lastName,
              relationshipId: details.relationshipTypeId,
              email: details.email,
            }}
            handleClose={() => {
              closeFunction?.();
            }}
          />
        ) : null}
      </div>

      {isCompany ? (
        <div
          className={cn("ps-5", classes.section, {
            [classes.bordered]: true,
          })}
        >
          <div className="d-flex align-items-center mb-3">
            <EmailIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />
            {details?.email ? (
              <a href={`mailto:${details.email}`} className="d-flex align-items-center">
                <Ui.s underline color="primary1" className="me-half">
                  {details.email}
                </Ui.s>

                <LinkHelpText
                  title=""
                  overlayClass={classes["email-overlay"]}
                  content={t("copyAddress")}
                  onClick={() => {
                    navigator.clipboard.writeText(defaultTo("", details.email)).then(() => {
                      notify(t("emailCopied"), true, "success", 1500);
                    });
                  }}
                >
                  <CopyNewIcon color={scssVariables.primary1} strokeWidth={0.6} />
                </LinkHelpText>
              </a>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center mb-3">
            <CompanyBuildingIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

            {details?.organizationNumber ? (
              <Ui.m>{details.organizationNumber}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center pe-2 mb-3">
            <PinLocationIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />

            {details?.address ? (
              <Ui.m>{details.address}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center pe-2 mb-3">
            <MailboxIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />

            {details?.businessPostAddress ? (
              <Ui.m>{details.businessPostAddress}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center pe-2 mb-3">
            <NewAvatar
              company={false}
              imageUrl={details?.avatarFilePath}
              initials={`${details?.firstName && details?.firstName[0]}${details?.lastName && details.lastName[0]}`}
              className={cn(classes.avatar, "me-4")}
            />

            <Ui.m>{`${details?.firstName} ${details?.lastName}`}</Ui.m>
          </div>

          <div className="d-flex align-items-center">
            <PhoneIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

            {details?.phoneNumber ? (
              <Ui.m>{details.phoneNumber}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>
        </div>
      ) : (
        <div className={cn("ps-5", classes.section, classes.bordered)}>
          <div className="d-flex align-items-center mb-3">
            <EmailIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

            {details?.email ? (
              <a href={`mailto:${details.email}`} className="d-flex align-items-center">
                <Ui.s underline color="primary1" className="me-half">
                  {details.email}
                </Ui.s>

                <LinkHelpText
                  title=""
                  content={t("copyAddress")}
                  overlayClass={classes["email-overlay"]}
                  onClick={() => {
                    navigator.clipboard.writeText(defaultTo("", details.email)).then(() => {
                      notify(t("emailCopied"), true, "success", 1500);
                    });
                  }}
                >
                  <CopyNewIcon color={scssVariables.primary1} strokeWidth={0.6} />
                </LinkHelpText>
              </a>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center mb-3">
            <PhoneIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

            {details?.phoneNumber ? (
              <Ui.m>{details.phoneNumber}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center mb-3">
            <CalendarIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />

            {details?.dateOfBirth ? (
              <Ui.m>{transformDateToCommonDateFormat(details.dateOfBirth)}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>

          <div className="d-flex align-items-center pe-2">
            <PinLocationIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />
            {details?.address ? (
              <Ui.m>{details?.address}</Ui.m>
            ) : (
              <div style={{ color: scssVariables.foregroundLow }}>-</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
