import axios from "axios";
import { format } from "date-fns";
import { action, actionOn, createContextStore, thunk } from "easy-peasy";

import { CapTableGroupByOptions } from "common/enums/enum";
import store from "store/store";

import {
  CapTableIsLivePostRequest,
  CapTableServiceGetResponse,
  CapTableStoreModel,
  DilutedCapTableServiceGetResponse,
} from "./CapTable.types";

const API_BASE = "/api/equity-management/cap-table";

const actualData = {
  capTable: [],
  capTableByShareholder: [],
  capTableByShareHolder: [],
  capTableSummary: {
    numberOfShares: 0,
    valuation: 0,
    percentage: 0,
  },
  isCapTableLive: true,
  hasCompanyInvitation: false,
  showAddShareholderBanner: false,
  issuedUnissuedDistribution: [],
  relationshipOverview: [],
  shareClassDistribution: [],
  topStakeholders: [],
};

const CapTableStore = createContextStore<CapTableStoreModel, Record<string, unknown>>(
  () => ({
    searchValue: "",
    setSearchValue: action((state, payload) => {
      state.searchValue = payload;
    }),

    shareholderId: undefined,
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),
    isValuationLoading: false,
    setIsValuationLoading: action((state, payload) => {
      state.isValuationLoading = payload;
    }),

    finishSetupMode: false,
    setFinishSetupMode: action((state, payload) => {
      state.finishSetupMode = payload;
    }),

    actualData: actualData,
    setActualData: action((state, payload) => {
      state.actualData = payload;
    }),

    selectedDateForActualCapTable: null,
    setSelectedDateForActualCapTable: action((state, payload) => {
      state.selectedDateForActualCapTable = payload;
    }),

    getActualDataThunk: thunk(
      async (actions, { companyId = store.getState().activeCompanyModel.companyId || 0, date = new Date() }) => {
        try {
          actions.setIsLoading(true);

          const request = await axios.get<CapTableServiceGetResponse>(
            `${API_BASE}/${companyId}/${format(date ? date : new Date(), "yyyy-MM-dd HH:mm")}`
          );

          if (request.status === 200) {
            actions.setActualData(request.data);
          }
        } finally {
          actions.setIsLoading(false);
        }
      }
    ),
    isCaptableLiveThunk: thunk(async (actions, companyId = store.getState().activeCompanyModel.companyId || 0) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.post<CapTableIsLivePostRequest>(`${API_BASE}/go-live`, {
          companyId,
        });

        if (request.status === 200) {
          actions.getActualDataThunk({
            companyId: companyId,
          });

          return true;
        }
      } catch (e) {
        console.warn({ e });
      } finally {
        actions.setIsLoading(false);
      }
    }),

    dilutedData: actualData,
    setDilutedData: action((state, payload) => {
      state.dilutedData = payload;
    }),
    getDilutedDataThunk: thunk(async (actions, payload) => {
      const companyId = payload.companyId || store.getState().activeCompanyModel.companyId;
      const valuation = payload.valuation || "";
      try {
        valuation ? actions.setIsValuationLoading(true) : actions.setIsLoading(true);

        const request = await axios.get<DilutedCapTableServiceGetResponse>(
          `${API_BASE}/fully-diluted/${companyId}/${valuation}`
        );

        if (request.status === 200) {
          actions.setDilutedData(request.data);
        }
      } finally {
        valuation ? actions.setIsValuationLoading(false) : actions.setIsLoading(false);
      }
    }),

    selectedActualGroup: CapTableGroupByOptions.default,
    setSelectedActualGroup: action((state, payload) => {
      state.selectedActualGroup = payload;
    }),
    selectedActualGroupChangeHandler: actionOn(
      (actions) => actions.setSelectedActualGroup,
      (state) => {
        state.selectedRowData = undefined;
      }
    ),

    selectedDilutedGroup: CapTableGroupByOptions.default,
    setSelectedDilutedGroup: action((state, payload) => {
      state.selectedDilutedGroup = payload;
    }),
    selectedDilutedGroupChangeHandler: actionOn(
      (actions) => actions.setSelectedDilutedGroup,
      (state) => {
        state.selectedRowData = undefined;
      }
    ),

    offCanvasOpen: false,
    setOffCanvasOpen: action((state, payload) => {
      state.offCanvasOpen = payload.open;
      state.shareholderId = payload.shareholderId;
    }),
    shareholder: null,
    setShareholder: action((state, payload) => {
      state.shareholder = payload;
    }),

    removeShareholder: null,
    removeOffCanvasOpen: false,
    setRemoveOffCanvasOpen: action((state, payload) => {
      state.removeOffCanvasOpen = payload.open;
      state.removeShareholder = payload?.data || null;
    }),

    setSelectedRowData: action((state, payload) => {
      state.selectedRowData = payload;
    }),

    setShowAddShareholderBanner: action((state, payload) => {
      state.actualData.showAddShareholderBanner = payload;
    }),
  }),
  {
    name: "Captable",
  }
);

export default CapTableStore;
