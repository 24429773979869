import { FC, useMemo, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import * as R from "ramda";

import { PoolStatuses } from "common/enums/enum";
import { useStoreState } from "store/store";

import PoolItem from "./PoolItem/PoolItem";

export type PoolsComponent = FC;

const Pools: PoolsComponent = () => {
  const location = useLocation();
  const refs = useRef<HTMLDivElement[]>([]);

  const { poolsAndPrograms } = useStoreState((state) => state.company);

  const activePools = useMemo(() => poolsAndPrograms?.pools?.sort((a, b) => a.status - b.status), [poolsAndPrograms]);

  const openedPoolSection = useMemo(() => {
    if (location.state?.createdPoolId) {
      return String(poolsAndPrograms?.pools?.find((el) => el.id === location.state?.createdPoolId)?.id);
    }

    if (location.state?.createdProgramId) {
      const searchedPool = poolsAndPrograms?.pools
        ?.filter((el) => el.statusId !== PoolStatuses.draft)
        .find((el) => R.pluck("id", el.ownershipPrograms).includes(R.defaultTo(0, location.state?.createdProgramId)));

      if (!R.isNil(searchedPool)) {
        return String(searchedPool?.id);
      }
    }

    return "";
  }, [location, poolsAndPrograms]);

  const addToRefs = (el: HTMLDivElement) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  return (
    <Accordion alwaysOpen defaultActiveKey={[openedPoolSection]}>
      {activePools?.map((pool) => (
        <PoolItem pool={pool} key={pool.id} ref={addToRefs} />
      ))}
    </Accordion>
  );
};

export default Pools;
