import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, Tag } from "common/components/atoms";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { UploadIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { downloadExcelFile } from "common/utils/download";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import classes from "../cap-table/common/actions/CaptableActions.module.scss";
import AddTransactionModal from "./components/add-transaction-modal/add-transaction-modal";
import ConfirmTransactionModal from "./components/confirm-transaction-modal/confirm-transaction-modal";
import DeletePendingTransactionModal from "./components/delete-pending-transaction-modal/delete-pending-transaction-modal";
import BuySellContainer from "./components/forms/buy-sell/buy-sell-container";
import ChangeNominalValueContainer from "./components/forms/change-nominal-value/change-nominal-value-container";
import IssueSharesContainer from "./components/forms/issue-shares/issue-shares-container";
import SplitContainer from "./components/forms/split/split-container";
import RollbackConfirmedTransaction from "./components/rollback-confirmed-transaction/rollback-confirmed-transaction";
import TransactionsList from "./components/transactions-list/transactions-list";
import { useTransactionsService } from "./transactions-service";
import {
  BuySellGetResponseDTO,
  ConfirmCapitalIncreaseTransactionPostDTO,
  ConfirmIssueSharesTransactionPostDTO,
  ConfirmNominalValuePostDTO,
  ConfirmSplitTransactionPostDTO,
  IssueSharesGetResponseDTO,
  NominalValueGetResponseDTO,
  SplitGetResponseDTO,
  Transaction,
  TransactionCategory,
  TransactionCategoryIds,
  TransactionStatus,
} from "./types";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.transactions"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const Transactions: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId = "0" } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const { hasFullAccess } = useFeatures(FEATURES.transactions);
  const {
    getTransactions,
    getIssueSharesTransaction,
    getBuySellTransaction,
    getSplitTransaction,
    getSingleNominalValueDetails,
    postConfirmIssueSharesTransaction,
    postConfirmSplitTransaction,
    postConfirmCapitalIncreaseTransaction,
    deleteTransaction,
    deleteCapitalIncreaseTransaction,
    postConfirmNominalValueTransaction,
  } = useTransactionsService(companyId);

  const [isAddTransactionModalOpen, setIsAddTransactionModalOpen] = useState(false);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isCompanyHasShares, setIsCompanyHasShares] = useState(false);
  const [minAllowedTransactionDate, setMinAllowedTransactionDate] = useState<string>();
  const [initialNominalValue, setInitialNominalValue] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);

  const scrollRef = useRef<HTMLUListElement>(null);
  const [elementIdToScroll, setElementIdToScroll] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    getTransactions().then((response) => {
      setTransactions(response.transactions);
      setIsCompanyHasShares(response.companyHasShares);
      setMinAllowedTransactionDate(response.transactedAtMax);
      setInitialNominalValue(response.nominalValue);
      setIsLoading(false);
    });
  }, [getTransactions, companyId]);

  useEffect(() => {
    if (elementIdToScroll) {
      const transactionsElements = Array.from(scrollRef?.current?.children || []);
      const elementToScroll = transactionsElements.find((element) => element.id === elementIdToScroll);
      if (elementToScroll) {
        elementToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setElementIdToScroll(null);
    }
  }, [elementIdToScroll]);

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    const response = await getTransactions();
    setTransactions(response.transactions);
    setIsCompanyHasShares(response.companyHasShares);
    setMinAllowedTransactionDate(response.transactedAtMax);
    setInitialNominalValue(response.nominalValue);
    setIsLoading(false);
  }, [getTransactions]);

  const handleCloseForms = useCallback(() => {
    setIsBuySellFormOpen(false);
    setIsIssueSharesFormOpen(false);
    setIsSplitFormOpen(false);
    setIsChangeNominalValueFormOpen(false);

    setTransactionToConfirm(null);
    setIssueSharesTransactionToEdit(null);
    setSplitTransactionToEdit(null);
    setTransactionIdToDelete(null);
    setNominalValueToEdit(null);
    setCapitalIncreaseIdToDelete(null);
    setConfirmedTransactionToRollback(null);
    setBuySellTransactionToEdit(null);
  }, []);

  const handleSubmitForms = useCallback(
    async (transactionId?: string, keepFormOpen: boolean = false) => {
      await fetchTransactions();

      if (!keepFormOpen) {
        handleCloseForms();

        if (transactionId) {
          setElementIdToScroll(transactionId);
        }
      }
    },
    [handleCloseForms, fetchTransactions]
  );

  // Issue shares
  const [isIssueSharesFormOpen, setIsIssueSharesFormOpen] = useState(false);
  const [issueSharesTransactionToEdit, setIssueSharesTransactionToEdit] = useState<IssueSharesGetResponseDTO | null>(
    null
  );

  // Buy/Sell
  const [isBuySellFormOpen, setIsBuySellFormOpen] = useState(false);
  const [buySellTransactionToEdit, setBuySellTransactionToEdit] = useState<BuySellGetResponseDTO | null>(null);

  // Delete
  const [transactionIdToDelete, setTransactionIdToDelete] = useState<number | null>(null);
  const [capitalIncreaseIdToDelete, setCapitalIncreaseIdToDelete] = useState<number | null>(null);
  const [confirmedTransactionToRollback, setConfirmedTransactionToRollback] = useState<Transaction | null>(null);

  const handleOpenDeleteTransaction = useCallback((transactionId: number) => {
    setTransactionIdToDelete(transactionId);
  }, []);

  const handleOpenDeleteCapitalIncrease = useCallback((capitalIncreaseId: number) => {
    setCapitalIncreaseIdToDelete(capitalIncreaseId);
  }, []);

  const handleOpenRollbackTransaction = useCallback((transaction: Transaction) => {
    setConfirmedTransactionToRollback(transaction);
  }, []);

  const handleSubmitDeleteTransaction = useCallback(
    async (transactionId: number, isRollback?: boolean) => {
      setIsLoading(true);
      const deletionSuccess = await deleteTransaction(transactionId);

      if (deletionSuccess) {
        notify(
          t(isRollback ? "successTransactionRollback" : "successTransactionDeleted"),
          true,
          "success",
          5000,
          false,
          "top-center"
        );

        const response = await getTransactions();
        setTransactions(response.transactions);
        setIsCompanyHasShares(response.companyHasShares);
        setMinAllowedTransactionDate(response.transactedAtMax);

        setTransactionIdToDelete(null);
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    },
    [deleteTransaction, getTransactions]
  );

  // Split
  const [isSplitFormOpen, setIsSplitFormOpen] = useState(false);
  const [splitTransactionToEdit, setSplitTransactionToEdit] = useState<SplitGetResponseDTO | null>(null);

  // Change nominal value
  const [isChangeNominalValueFormOpen, setIsChangeNominalValueFormOpen] = useState(false);
  const [isNominalValueDataUpdated, setIsNominalValueDataUpdated] = useState(false);
  const [nominalValueToEdit, setNominalValueToEdit] = useState<NominalValueGetResponseDTO | null>(null);

  // Confirm
  const [transactionToConfirm, setTransactionToConfirm] = useState<Transaction | null>(null);

  const handleOpenConfirmTransaction = useCallback((transaction: Transaction) => {
    setTransactionToConfirm(transaction);
  }, []);

  const handleSubmitConfirmTransaction = useCallback(
    async (
      confirmTransactionData: ConfirmIssueSharesTransactionPostDTO | ConfirmSplitTransactionPostDTO,
      transactionCategory: TransactionCategoryIds
    ) => {
      let confirmationSuccess;

      setIsLoading(true);
      if (transactionCategory === TransactionCategory.Issue) {
        confirmationSuccess = await postConfirmIssueSharesTransaction(
          confirmTransactionData as ConfirmIssueSharesTransactionPostDTO
        );
      }

      if (transactionCategory === TransactionCategory.Split) {
        confirmationSuccess = await postConfirmSplitTransaction(
          confirmTransactionData as ConfirmSplitTransactionPostDTO
        );
      }

      if (transactionCategory === TransactionCategory.ChangeNominalValue) {
        confirmationSuccess = await postConfirmNominalValueTransaction({
          transactionId: confirmTransactionData.transactionId,
          transactionDate: confirmTransactionData.transactedAt,
          description: confirmTransactionData.description,
        } as ConfirmNominalValuePostDTO);
      }

      if (transactionCategory === TransactionCategory.CapitalIncrease) {
        confirmationSuccess = await postConfirmCapitalIncreaseTransaction({
          transactionBundleId: confirmTransactionData.transactionId,
          transactedAt: confirmTransactionData.transactedAt,
          description: confirmTransactionData.description,
        } as ConfirmCapitalIncreaseTransactionPostDTO);
      }

      if (confirmationSuccess) {
        notify(t("successTransactionConfirmation"), true, "success", 5000, false, "top-center");
        const response = await getTransactions();
        setTransactions(response.transactions);
        setIsCompanyHasShares(response.companyHasShares);
        setMinAllowedTransactionDate(response.transactedAtMax);
        setInitialNominalValue(response.nominalValue);

        setTransactionToConfirm(null);
        setIsLoading(false);

        //@ts-ignore
        setElementIdToScroll(confirmTransactionData.transactionBundleId);
        return true;
      }
      setIsLoading(false);
      return false;
    },
    [
      getTransactions,
      postConfirmCapitalIncreaseTransaction,
      postConfirmIssueSharesTransaction,
      postConfirmNominalValueTransaction,
      postConfirmSplitTransaction,
    ]
  );

  // Capital increase

  const handleOpenCapitalIncrease = useCallback(() => {
    navigate(
      generatePath(
        getEMPath(["createCapitalIncrease", "general"], {
          transactionId: "new",
        })
      ),
      {
        state: { minTransactionsDate: minAllowedTransactionDate },
      }
    );
  }, [minAllowedTransactionDate, navigate]);

  const handleSubmitDeleteCapitalIncrease = useCallback(
    async (capitalIncreaseId: number) => {
      const deletionSuccess = await deleteCapitalIncreaseTransaction(capitalIncreaseId);

      if (deletionSuccess) {
        notify(t("successTransactionDeleted"), true, "success", 5000, false, "top-center");

        const response = await getTransactions();
        setTransactions(response.transactions);
        setIsCompanyHasShares(response.companyHasShares);
        setMinAllowedTransactionDate(response.transactedAtMax);

        setCapitalIncreaseIdToDelete(null);
        return true;
      }
      return false;
    },
    [deleteCapitalIncreaseTransaction, getTransactions]
  );

  // common

  const handleOpenEditTransaction = useCallback(
    async (transactionId: number, transactionCategoryId: TransactionCategoryIds) => {
      if (transactionCategoryId === TransactionCategory.Issue) {
        setIssueSharesTransactionToEdit(await getIssueSharesTransaction(transactionId));
      }

      if (transactionCategoryId === TransactionCategory.Sell) {
        setBuySellTransactionToEdit(await getBuySellTransaction(transactionId));
      }

      if (transactionCategoryId === TransactionCategory.Split) {
        setSplitTransactionToEdit((await getSplitTransaction(transactionId)) || null);
      }

      if (transactionCategoryId === TransactionCategory.CapitalIncrease) {
        navigate(
          generatePath(getEMPath(["createCapitalIncrease", "general"], { transactionId: String(transactionId) })),
          {
            state: { minTransactionsDate: minAllowedTransactionDate },
          }
        );
      }

      if (transactionCategoryId === TransactionCategory.ChangeNominalValue) {
        const getNominalValueDetails = await getSingleNominalValueDetails(transactionId);

        if (getNominalValueDetails) {
          setNominalValueToEdit(getNominalValueDetails);
        }
      }
    },
    [
      getBuySellTransaction,
      getIssueSharesTransaction,
      getSingleNominalValueDetails,
      getSplitTransaction,
      minAllowedTransactionDate,
      navigate,
    ]
  );

  const hasPendingSplitTransaction = useMemo(() => {
    return !!transactions.find(
      (transaction) =>
        transaction.categoryId === TransactionCategory.Split && transaction.statusId === TransactionStatus.Pending
    );
  }, [transactions]);

  const hasPendingTransaction = useMemo(() => {
    return !!transactions.find((transaction) => transaction.statusId === TransactionStatus.Pending);
  }, [transactions]);

  const handleManageAddTransactionModal = useCallback(() => {
    setIsAddTransactionModalOpen((prev) => !prev);
  }, []);

  const handleExport = useCallback(async () => {
    downloadExcelFile(`/api/export/transactions/${companyId}`, "Transactions data");
  }, [companyId]);

  return (
    <PageContent data-testid="transactions-page-test-id">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>

        <Button
          size="s"
          variant="tertiary"
          className={classNames(classes["export-btn"], "ms-auto me-3")}
          iconRight={<UploadIcon className="ms-half" />}
          onClick={handleExport}
        >
          Export
        </Button>
        <Button
          isFocusDisabled
          isDisabled={!hasFullAccess}
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          onClick={handleManageAddTransactionModal}
        >
          {t("addTransaction.btn")}
        </Button>
      </PageContent.Header>
      <div>
        <LoaderContainer loading={isLoading}>
          <TransactionsList
            transactions={transactions}
            handleOpenEditTransaction={handleOpenEditTransaction}
            handleOpenConfirmTransaction={handleOpenConfirmTransaction}
            handleOpenDeleteTransaction={handleOpenDeleteTransaction}
            handleOpenDeleteCapitalIncrease={handleOpenDeleteCapitalIncrease}
            handleOpenRollbackTransaction={handleOpenRollbackTransaction}
            scrollRef={scrollRef}
            itemIdToOpen={elementIdToScroll}
            isNominalDataUpdates={isNominalValueDataUpdated}
            setIsNominalValueDataUpdated={setIsNominalValueDataUpdated}
          />
        </LoaderContainer>

        <IssueSharesContainer
          isIssueSharesFormOpen={isIssueSharesFormOpen}
          issueSharesTransactionToEdit={issueSharesTransactionToEdit}
          onSubmit={handleSubmitForms}
          onClose={handleCloseForms}
          isDisabled={hasPendingSplitTransaction}
        />

        <BuySellContainer
          isBuySellFormOpen={isBuySellFormOpen}
          buySellTransactionToEdit={buySellTransactionToEdit}
          onSubmit={handleSubmitForms}
          onClose={handleCloseForms}
          isDisabled={hasPendingSplitTransaction}
        />

        <SplitContainer
          isSplitFormOpen={isSplitFormOpen}
          splitTransactionToEdit={splitTransactionToEdit}
          onSubmit={handleSubmitForms}
          onClose={handleCloseForms}
          minTransactionDate={minAllowedTransactionDate}
          isSplitDisabled={hasPendingTransaction}
        />

        <ChangeNominalValueContainer
          isDisabled={false}
          isOpen={isChangeNominalValueFormOpen}
          dataToEdit={nominalValueToEdit}
          onSubmit={handleSubmitForms}
          onClose={handleCloseForms}
          setIsNominalValueDataUpdated={setIsNominalValueDataUpdated}
          initialNominalValue={initialNominalValue}
          minTransactionDate={minAllowedTransactionDate}
        />

        {!!transactionToConfirm && (
          <ConfirmTransactionModal
            transaction={transactionToConfirm}
            minTransactionDate={minAllowedTransactionDate}
            onSubmit={handleSubmitConfirmTransaction}
            onClose={handleCloseForms}
          />
        )}

        {!!transactionIdToDelete && (
          <DeletePendingTransactionModal
            transactionId={transactionIdToDelete}
            onSubmit={handleSubmitDeleteTransaction}
            onClose={handleCloseForms}
          />
        )}
        {!!capitalIncreaseIdToDelete && (
          <DeletePendingTransactionModal
            transactionId={capitalIncreaseIdToDelete}
            onSubmit={handleSubmitDeleteCapitalIncrease}
            onClose={handleCloseForms}
          />
        )}
        {!!confirmedTransactionToRollback && (
          <RollbackConfirmedTransaction
            transactionToRollback={confirmedTransactionToRollback}
            onClose={handleCloseForms}
            onSubmit={handleSubmitDeleteTransaction}
          />
        )}
      </div>
      <AddTransactionModal
        isOpen={isAddTransactionModalOpen}
        isCompanyHasShares={isCompanyHasShares}
        hasPendingSplitTransaction={hasPendingSplitTransaction}
        setIsBuySellFormOpen={setIsBuySellFormOpen}
        setIsIssueSharesFormOpen={setIsIssueSharesFormOpen}
        handleOpenCapitalIncrease={handleOpenCapitalIncrease}
        setIsChangeNominalValueFormOpen={setIsChangeNominalValueFormOpen}
        setIsSplitFormOpen={setIsSplitFormOpen}
        onClose={handleManageAddTransactionModal}
      />
    </PageContent>
  );
};

export default Transactions;
