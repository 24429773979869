import { FC } from "react";
import cn from "classnames";

import { Transaction, TransactionCategory, TransactionCategoryIds, TransactionStatus } from "../../../types";
import NominalValueItemBody from "../../forms/form-sections/nominal-value-details/nominal-value-item-body";
import CapitalIncreaseBody from "./transaction-bodies/capital-increase-body";
import DeletionBody from "./transaction-bodies/deletion-body";
import IssueSharesAndBuySellBody from "./transaction-bodies/issue-shares-and-buy-sell-body";
import SplitBody from "./transaction-bodies/split-body";
import classes from "./transaction-item.module.scss";

type PropsTypes = {
  transaction: Transaction;
  eventKey: string;
  isNominalDataUpdates?: boolean;
  setIsNominalValueDataUpdated?: (val: boolean) => void;
  contextMenuHandlers: {
    handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
    handleOpenConfirmTransaction?: (transaction: Transaction) => void;
    handleOpenDeleteTransaction?: (transactionId: number) => void;
    handleOpenRollbackTransaction?: (transaction: Transaction) => void;
  };
};

const Body: FC<PropsTypes> = ({
  eventKey,
  transaction,
  isNominalDataUpdates,
  setIsNominalValueDataUpdated,
  contextMenuHandlers,
}) => {
  if (transaction.categoryId === TransactionCategory.Split) {
    return (
      <div className={classes.bodyContainer}>
        <SplitBody
          isConfirmed={transaction.statusId === TransactionStatus.Confirmed || false}
          transactionId={transaction.transactionId}
          multiplier={transaction?.multiplier || 1}
          description={transaction.description}
          eventKey={eventKey}
        />
      </div>
    );
  }

  if (
    transaction.categoryId === TransactionCategory.CapitalIncrease ||
    transaction.categoryId === TransactionCategory.ShareTransfer
  ) {
    return (
      <div className={cn("p-4", classes.bodyContainer, classes.darkBackground)}>
        <CapitalIncreaseBody
          bundleId={transaction.transactionBundleId!}
          bundleStatusId={transaction.statusId}
          eventKey={eventKey}
          contextMenuHandlers={contextMenuHandlers}
        />
      </div>
    );
  }

  if (transaction.categoryId === TransactionCategory.ChangeNominalValue) {
    return (
      <div className={classes.bodyContainer}>
        <NominalValueItemBody
          transaction={transaction}
          isNominalDataUpdates={isNominalDataUpdates}
          setIsNominalValueDataUpdated={setIsNominalValueDataUpdated}
        />
      </div>
    );
  }

  if (transaction.categoryId === TransactionCategory.Deletion) {
    return (
      <div className={cn("p-4", classes.bodyContainer, classes.darkBackground)}>
        <DeletionBody transaction={transaction} />
      </div>
    );
  }

  return (
    <div className={cn("p-4", classes.bodyContainer, classes.darkBackground)}>
      <IssueSharesAndBuySellBody transaction={transaction} />
    </div>
  );
};

export default Body;
