import { useCallback, useMemo } from "react";
import { Form, useFormikContext } from "formik";
import { defaultTo, isNil } from "ramda";

import { Helper, P, SlidePanel, SubscriptionLimitsReached } from "common/components/atoms";
import SlowRequestInfo from "common/components/atoms/SlowRequestInfo/SlowRequestInfo";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import VestingPlanDetails from "../../../steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { PlanFormType } from "../../../steps/basics/sidebar/forms/plan-form";
import { PlanForm } from "../../../types";
import ApprovalInformation from "../../form-parts/approval-information/approval-information";
import Footer from "../../form-parts/footer/footer";
import ShareDetails from "../../form-parts/share-details/share-details";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const FormData = ({ handleClose }: { handleClose: (isOpen: boolean) => void }) => {
  const selectedProgram = useStoreState((state) => state.programModel.program);

  const { handleSubmit, isSubmitting, values } = useFormikContext<PlanForm>();

  const isFormDisabled =
    !isNil(values.subscriptionAvailable) && defaultTo(0, values.subscriptionAvailable.remainingAgreements) <= 0;

  const handleAddPlan = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(
    () => (values.sendEmailInvitation ? { email: { isTopField: true, meta: { isOptional: false } } } : undefined),
    [values.sendEmailInvitation]
  );

  return (
    <Form>
      <ToastFormikValidator />
      <Helper>
        <SlidePanel.Section
          title={<Helper.Question questionId="receiverDetails">{t("receiverBasics")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="receiverDetails"
            text={t("receiverDetailsAnswer")}
            linkText={t("receiverDetailsReadMore")}
            link="/"
          />
          <StakeholderSection updatedFields={updatedFields} isAgreementsFlow />

          {isFormDisabled ? (
            <div className="mt-3">
              <SubscriptionLimitsReached numberOfPlans={values.subscriptionAvailable?.maximumNumberAgreements || 0} />
            </div>
          ) : null}
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="shareDetailsCollapsible" title={t("shareDetails")}>
          <ShareDetails
            shareClass={selectedProgram?.shareClassName}
            planType={selectedProgram?.instrumentTypeId}
            programName={selectedProgram?.name}
          />
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="vestingDetailsCollapsible" title={t("vestingDetails")}>
          <VestingPlanDetails instrumentType={InstrumentTypesIdsEnum.RSA} />
        </SlidePanel.Section>

        <SlidePanel.Section isDividerVisible={false} title={t("approvalInformation")}>
          <ApprovalInformation />
        </SlidePanel.Section>

        <SlidePanel.Section isDividerVisible={false} data-testid="exerciseDocumentCollapsible" title={t("document")}>
          <P.m>{t("documentsDescription")}</P.m>

          <GeneratePlanDocument
            autoGenerateAgreement={false}
            managerSignature={false}
            planFormType={PlanFormType.ExistRSAPlan}
          />
        </SlidePanel.Section>

        <SlowRequestInfo isSubmitting={isSubmitting} />

        <Footer handleAddPlan={handleAddPlan} handleClose={handleClose} isSubmitting={isSubmitting} />
      </Helper>
    </Form>
  );
};

export default FormData;
