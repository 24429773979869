import { FC, useCallback, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import cn from "classnames";
import { useFormikContext } from "formik";

import { DatePicker, H, Helper, TextField, Ui } from "common/components/atoms";
import SigningManagerField from "common/components/molecules/signing-manager/SigningManagerField";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import PoolShareClassField from "common/pool/components/PoolForm/PoolShareClassField/PoolShareClassField";
import { ShareClassServiceCompanyShareClassesResponse } from "common/shareClass/ShareClassService";
import { scssVariables } from "common/utils/constants";
import { SOURCE_IS_EXISTING_SHARES_ID, SOURCE_IS_NEW_SHARES_ID } from "store/PoolModel";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import EssentialTermsField from "../../../../create-plan/fields/EssentialTermsField";
import { OwnershipPlanForm } from "../../../CreateGrantOneOffPlan";
import PoolStatus from "../PoolStatus/PoolStatus";
import classes from "./OneOffRSA.module.scss";
import StakeholderShareClasses from "./StakeholderShareClasses/StakeholderShareClasses";

type PropsType = {
  hideShareDetails?: boolean;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const OneOffRSA: FC<PropsType> = ({ hideShareDetails = false }) => {
  const [shareClasses, setShareClasses] = useState<ShareClassServiceCompanyShareClassesResponse>([]);
  const { formattedCurrency } = useCurrency();
  const fNumber = useFormatNumbers();

  const instrumentTypes = useStoreState((state) => state.common.dropdowns?.instrumentTypes);

  const { values, handleChange, errors, touched, handleBlur, setFieldValue } = useFormikContext<OwnershipPlanForm>();

  const instrumentTypeName = useMemo(
    () => instrumentTypes?.find((type) => type.id === values?.planTypeId)?.name,
    [instrumentTypes, values?.planTypeId]
  );
  const shareClassName = useMemo(() => {
    return shareClasses?.find((type) => type.id === values?.shareClassId)?.name;
  }, [shareClasses, values?.shareClassId]);

  const totalPurchasePrice = useMemo(() => {
    if (values?.numberOfShares && values?.purchasePrice) {
      return values.numberOfShares * values.purchasePrice;
    }
    return 0;
  }, [values.numberOfShares, values.purchasePrice]);

  const EndSymbolRender = useCallback(
    () => <Ui.m style={{ color: scssVariables.foregroundMedium }}>{formattedCurrency}</Ui.m>,
    [formattedCurrency]
  );

  const handleBoardApprovalDateChange = useCallback(
    (date: Date | string) => {
      setFieldValue("approvedAt", date);
    },
    [setFieldValue]
  );

  const handleGrantDateChange = useCallback(
    (date: Date | string) => {
      setFieldValue(f.grantedAt, date);
    },
    [setFieldValue]
  );

  const sharesSourceIsStakeholder = useMemo(() => {
    return values.poolId === SOURCE_IS_EXISTING_SHARES_ID;
  }, [values.poolId]);

  return (
    <div className="mt-0 pb-5">
      <Row
        className="mb-3"
        style={{
          display: values.poolId === SOURCE_IS_NEW_SHARES_ID || sharesSourceIsStakeholder ? "" : "none",
        }}
      >
        <Col>
          {values.sharesFromStakeholderId ? (
            <StakeholderShareClasses />
          ) : (
            <PoolShareClassField getShareClassesCallback={setShareClasses} />
          )}
        </Col>
        <Col />
      </Row>

      <Row className="mb-5 align-items-end">
        <Col>
          <TextField
            type="number"
            label={t("amountOfShares")}
            value={values.numberOfShares}
            isTouched={touched.numberOfShares}
            error={errors.numberOfShares}
            name={f.numberOfShares}
            onChange={handleChange}
            onBlur={handleBlur}
            info={`Total purchase price: ${fNumber(totalPurchasePrice, "value")}`}
          />
        </Col>
        <Col>
          {values.poolId === SOURCE_IS_NEW_SHARES_ID || sharesSourceIsStakeholder ? (
            <TextField
              min={0}
              step={0.1}
              type="number"
              label={t("purchasePrice")}
              isTouched={touched.purchasePrice}
              error={errors.purchasePrice}
              value={values.purchasePrice}
              name={f.purchasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              iconRight={EndSymbolRender()}
            />
          ) : (
            <PoolStatus
              numberOfShares={values.numberOfShares || 0}
              numberOfSharesLeft={values?.numberOfSharesLeft || 0}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Helper>
          <Col xs={6}>
            <Helper.Question questionId="signing-manager" type="input">
              <SigningManagerField
                label={t("signingManager")}
                error={errors.managerId}
                isTouched={touched.managerId}
                selectedId={values?.managerId}
                onChange={(id) => {
                  setFieldValue("managerId", id);
                }}
              />
            </Helper.Question>
          </Col>

          <Helper.Answer className="mt-1" answerId="signing-manager" text={t("signingManagerAnswer")} />
        </Helper>
      </Row>

      {values.poolId !== SOURCE_IS_NEW_SHARES_ID && !sharesSourceIsStakeholder && (
        <Row className="mb-3">
          <Col>
            <TextField
              type="number"
              label={t("purchasePrice")}
              min={0}
              step={0.1}
              isTouched={touched.purchasePrice}
              error={errors.purchasePrice}
              value={values.purchasePrice}
              name={f.purchasePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              iconRight={EndSymbolRender()}
            />
          </Col>
          <Col />
        </Row>
      )}

      {!hideShareDetails && (
        <div className={cn(classes["neutral-plate"], "mt-3")}>
          <span className={classes["share-details-title"]}>{t("shareDetailsOneOffDescriptionTitle")}</span>
          <div className="d-flex justify-content-between pt-2">
            <div>
              <Ui.m>
                {t("shareClass")}: <strong>{shareClassName}</strong>
              </Ui.m>
              <Ui.m className="my-2">
                {t("type")} <strong>{instrumentTypeName}</strong>
              </Ui.m>
            </div>
          </div>
        </div>
      )}

      <div className={cn("mt-5 pt-5", classes["approval-info"])}>
        <H.xxs className="mb-3">{t("approvalInfo")}</H.xxs>

        <Row className="mb-4">
          <Col>
            <DatePicker
              isDateOnlyString
              label={t("boardApproval")}
              date={values.approvedAt}
              onChange={handleBoardApprovalDateChange}
            />
          </Col>
          <Col>
            <DatePicker
              isDateOnlyString
              label={t("grantDate")}
              date={values.grantedAt}
              onChange={handleGrantDateChange}
            />
          </Col>
        </Row>

        <Helper>
          <EssentialTermsField className="mt-4" />
        </Helper>
      </div>
    </div>
  );
};

export default OneOffRSA;
