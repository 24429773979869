import { memo, MouseEventHandler } from "react";
import { Col, Image } from "react-bootstrap";
import classNames from "classnames";
import { defaultTo, isNil } from "ramda";

import {
  Button,
  Check,
  DatePicker,
  Dropdown,
  Helper,
  P,
  SlidePanel,
  Tag,
  TextArea,
  TextField,
  Ui,
} from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat, transformUserNameToInitials } from "common/utils/functions";
import ManagePlansContext from "pages/equity-management/plans/manage-plans/managePlansContext";
import { createTranslation, TranslationNS } from "translation";

import OverrideShares from "./OverrideShares";
import classes from "./PlanTermination.module.scss";
import usePlanTermination from "./usePlanTermination";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.terminatePlan");

const PlanTermination = () => {
  const {
    errors,
    handleChange,
    handleClose,
    handleSubmit,
    setFieldValue,
    touched,
    values,
    terminationSoReasonVariants,
    terminationRsaReasonVariants,
    isOverrideSharesOpen,
    overrideSharesOpen,
    overrideSharesClose,
    updateOverrideShares,
  } = usePlanTermination();
  const { terminationPlanInfo } = ManagePlansContext.useStoreState((state) => state);

  const isLiquidationReasonSelected = values.terminationReason?.id === 7;

  const isRsa = terminationPlanInfo?.instrumentTypeId === InstrumentTypesIdsEnum.RSA;

  const vestedShares = terminationPlanInfo?.vestedShares || 0;
  const notVestedShares = terminationPlanInfo?.notVestedShares || 0;
  const notExercisedShares = vestedShares + notVestedShares;

  const notVestedFieldsValue = values.acceleratedVestingAccepted
    ? 0
    : values.newVestedShares
    ? notExercisedShares - values.newVestedShares
    : notVestedShares;

  return (
    <>
      <SlidePanel show={!isNil(terminationPlanInfo)} onHide={handleClose}>
        <SlidePanel.Header title={t("title")} onHide={handleClose} />

        <SlidePanel.Section isSingle title="">
          <div className={classes["user-card-info"]}>
            <div className="d-flex">
              <div className={classes["image"]}>
                {terminationPlanInfo?.stakeholder?.imageUrl ? (
                  <Image
                    alt={terminationPlanInfo?.stakeholder?.name}
                    src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${terminationPlanInfo?.stakeholder?.imageUrl}` || ""}
                  />
                ) : (
                  <Ui.xs className="fw-500">
                    {transformUserNameToInitials(terminationPlanInfo?.stakeholder?.name)}
                  </Ui.xs>
                )}
              </div>

              <div>
                <Ui.xl
                  bold
                  style={{
                    marginTop:
                      !terminationPlanInfo?.stakeholder?.companyName || isLiquidationReasonSelected ? 8 : undefined,
                  }}
                >
                  {terminationPlanInfo?.stakeholder?.name}
                </Ui.xl>
                {terminationPlanInfo?.stakeholder?.companyName ? (
                  <Ui.s style={{ color: scssVariables.foregroundLow }} className="mb-3">
                    {terminationPlanInfo?.stakeholder?.companyName}
                  </Ui.s>
                ) : null}
              </div>

              <Tag variant="closed" className={classes["tag"]}>
                {isRsa ? t("rsa") : t("stockOptions")}
              </Tag>
            </div>

            <div>
              {isRsa ? null : (
                <div className={classes["card-content"]}>
                  <Ui.m>{t("exercised")}</Ui.m>
                  <Ui.m style={{ color: scssVariables.foregroundMedium }}>
                    {t.el("sharesOwned", {
                      values: {
                        shares: terminationPlanInfo?.exercisedShares,
                      },
                      components: [<strong key={1} style={{ color: scssVariables.foregroundHigh }} />],
                    })}
                  </Ui.m>
                </div>
              )}

              <div className={classNames(classes["card-content"], "align-items-center")}>
                <Ui.m>{t("vestedNotExercised")}</Ui.m>
                <Ui.m style={{ color: scssVariables.foregroundMedium }}>
                  {t.el(isRsa ? "rsaSharesVested" : "optionsVested", {
                    values: {
                      options: values.newVestedShares ? values.newVestedShares : vestedShares,
                    },
                    components: [<strong key={1} style={{ color: scssVariables.foregroundHigh }} />],
                  })}
                </Ui.m>

                {values.acceleratedVestingAccepted ? null : (
                  <Button size="s" variant="tertiary" className={classes["edit-button"]} onClick={overrideSharesOpen}>
                    {t("edit")}
                  </Button>
                )}
              </div>

              <div className={classNames(classes["card-content"], "mb-0")}>
                <Ui.m>{t("notVested")}</Ui.m>
                <Ui.m style={{ color: scssVariables.foregroundMedium }}>
                  {t.el(isRsa ? "rsaNotVested" : "optionsNotVested", {
                    values: {
                      options: notVestedFieldsValue,
                    },
                    components: [
                      <strong
                        key={1}
                        style={{
                          color:
                            notVestedShares - values.newVestedShares === 0
                              ? scssVariables.foregroundHigh
                              : scssVariables.critical500,
                          textDecoration: notVestedShares - values.newVestedShares === 0 ? undefined : "line-through",
                        }}
                      />,
                    ],
                  })}
                </Ui.m>
              </div>
            </div>
          </div>

          <Helper>
            <Col className="d-flex align-items-center mt-4 mb-1" sm={6}>
              <Dropdown
                isOptional
                style={{ flexBasis: "90%" }}
                label={t("terminationReason")}
                selectedValue={values.terminationReason?.name}
                options={defaultTo([], terminationPlanInfo?.terminationOptions).map(({ name }) => name)}
                onChange={(e: string) => {
                  const selectedValue = terminationPlanInfo?.terminationOptions?.find(({ name }) => name === e);

                  setFieldValue("terminationReason", selectedValue);
                }}
              />

              <Helper.Question className="mt-3" questionId={defaultTo("", values.terminationReason?.name)} />
            </Col>

            <Helper.Answer
              answerId={defaultTo("", values.terminationReason?.name)}
              text={
                !values.terminationReason?.name
                  ? "Reason not selected."
                  : isRsa
                  ? terminationRsaReasonVariants.find(({ key }) => key === values.terminationReason?.name)?.text
                  : terminationSoReasonVariants.find(({ key }) => key === values.terminationReason?.name)?.text
              }
            />
          </Helper>

          {isRsa ? null : (
            <Col className="d-flex align-items-center my-4 mb-1 pe-5" sm={6}>
              <DatePicker
                isDateOnlyString
                label="Expiry date"
                date={values.expirationDate}
                error={errors.expirationDate}
                isTouched={touched.expirationDate}
                onChange={(date) => {
                  setFieldValue("expirationDate", date);
                }}
              />
            </Col>
          )}

          <Helper>
            <div className="d-flex align-items-center mb-1 mt-4">
              <Check
                label={t("acceleratedVesting")}
                checked={values.acceleratedVestingAccepted}
                onChange={handleChange("acceleratedVestingAccepted")}
              />

              <Helper.Question questionId="accelerated-vesting" />
            </div>

            <Helper.Answer answerId="accelerated-vesting" text={t("acceleratedVestingHelperText")} />
          </Helper>

          <TextArea
            isOptional
            className="mt-4 mb-2"
            placeholder={t("placeholder")}
            label={t(isRsa ? "rsaMessage" : "soMessage")}
            value={values.message}
            onChange={handleChange("message")}
          />

          <Col sm={6} className="mb-4">
            <Check
              className="mb-1"
              label={t(`notifyHolder.${isRsa ? "RSA" : "SO"}`)}
              checked={values.sendEmailInvitation}
              onChange={handleChange("sendEmailInvitation")}
            />
            {values.sendEmailInvitation ? (
              <TextField
                type="email"
                value={values.notifyEmail}
                error={errors.notifyEmail}
                isTouched={touched.notifyEmail}
                label={t("notifyHolderInputLabel")}
                onChange={handleChange("notifyEmail")}
              />
            ) : null}
          </Col>

          {Number(terminationPlanInfo?.vestedShares) + values.newVestedShares > 0 &&
          !isRsa &&
          !values.acceleratedVestingAccepted ? (
            <P.m className="mb-7">
              {t.el("description", {
                values: {
                  options: values.newVestedShares,
                  count: values.newVestedShares > 1 ? "options" : "option",
                  timeframe: transformDateToCommonDateFormat(values.expirationDate),
                  optionsPrice: `${terminationPlanInfo?.sharePrice} ${terminationPlanInfo?.currencySymbol}`,
                },
                components: [<strong key={1} />],
              })}
            </P.m>
          ) : null}

          {isRsa && (
            <div className="mt-4 mb-7">
              <Ui.l bold className="mb-1">
                {t("remainingSharesTitle")}
              </Ui.l>
              <P.m>{t("remainingSharesDescription")}</P.m>
            </div>
          )}

          <div className="d-flex">
            <Button variant="danger" onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}>
              {t("terminate")}
            </Button>

            <Button className="ms-2" variant="secondary" onClick={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </SlidePanel.Section>
      </SlidePanel>

      {isOverrideSharesOpen && <OverrideShares handleUpdate={updateOverrideShares} handleClose={overrideSharesClose} />}
    </>
  );
};

export default memo(PlanTermination);
