import { FC, useCallback, useMemo } from "react";
import { useAccordionButton } from "react-bootstrap";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Ui } from "common/components/atoms";
import ContextMenu from "common/components/atoms/ContextMenu/ContextMenu";
import { DeleteIcon, EditIcon, MenuTabBarVerticalIcon, ResetIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import {
  Transaction,
  TransactionCategory,
  TransactionCategoryIds,
  TransactionStatus,
  TransactionTypes,
} from "../../../types";
import NominalValueItemHead from "../../forms/form-sections/nominal-value-details/nominal-value-item-head";
import ProgrammaticAccordionToggle from "../programmatic-accordion-toggle/programmatic-accordion-toggle";
import CapitalIncreaseHeader from "./transaction-headers/capital-inscrease-header/capital-increase-header";
import DeletionHeader from "./transaction-headers/deletion-header";
import IssueSharesAndBuySellHeader from "./transaction-headers/issue-shares-and-buy-sell-header";
import SplitHeader from "./transaction-headers/split-header";

type PropsTypes = {
  transaction: Transaction;
  handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
  handleOpenConfirmTransaction?: (transaction: Transaction) => void;
  handleOpenDeleteTransaction?: (transactionId: number) => void;
  handleOpenDeleteCapitalIncrease?: (capitalIncreaseId: number) => void;
  handleOpenRollbackTransaction?: (transaction: Transaction) => void;
  eventKey: string;
  itemIdToOpen: string | null;
  accordionClickHandler?: (val: boolean) => void;
  portfolioView?: boolean;
  isMini?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const Header: FC<PropsTypes> = ({
  transaction,
  handleOpenEditTransaction,
  handleOpenConfirmTransaction,
  handleOpenDeleteTransaction,
  handleOpenDeleteCapitalIncrease,
  handleOpenRollbackTransaction,
  eventKey,
  itemIdToOpen,
  portfolioView,
  isMini,
}) => {
  const { hasFullAccess } = useFeatures(FEATURES.transactions);

  const decoratedOnClick = useAccordionButton(eventKey);

  const handleEditClick = useCallback(() => {
    if (transaction.categoryId === TransactionCategory.CapitalIncrease) {
      handleOpenEditTransaction?.(transaction.transactionBundleId!, transaction.categoryId);
    } else {
      handleOpenEditTransaction?.(transaction.transactionId, transaction.categoryId);
    }
  }, [handleOpenEditTransaction, transaction]);

  const handleConfirmClick = useCallback(() => {
    handleOpenConfirmTransaction?.(transaction);
  }, [handleOpenConfirmTransaction, transaction]);

  const handleDeleteClick = useCallback(() => {
    if (transaction.categoryId === TransactionCategory.CapitalIncrease && transaction.transactionBundleId) {
      handleOpenDeleteCapitalIncrease?.(transaction.transactionBundleId);
    } else {
      handleOpenDeleteTransaction?.(transaction.transactionId);
    }
  }, [
    handleOpenDeleteCapitalIncrease,
    handleOpenDeleteTransaction,
    transaction.categoryId,
    transaction.transactionBundleId,
    transaction.transactionId,
  ]);

  const handleRollbackClick = useCallback(() => {
    handleOpenRollbackTransaction?.(transaction);
  }, [handleOpenRollbackTransaction, transaction]);

  const isConfirmAllowed = useMemo(() => {
    if (
      transaction.categoryId === TransactionCategory.CapitalIncrease &&
      transaction.numberOfBundledTransactions === 0
    ) {
      return false;
    }

    return true;
  }, [transaction.categoryId, transaction.numberOfBundledTransactions]);

  const isPending = useMemo(() => transaction.statusId === TransactionStatus.Pending, [transaction]);
  const isSplit = useMemo(() => transaction.categoryId === TransactionCategory.Split, [transaction]);

  const isIssueOrBuySell = useMemo(
    () => transaction.categoryId === TransactionCategory.Issue || transaction.categoryId === TransactionCategory.Sell,
    [transaction]
  );
  const isBundle = useMemo(
    () =>
      transaction.categoryId === TransactionCategory.CapitalIncrease ||
      transaction.categoryId === TransactionCategory.ShareTransfer,
    [transaction]
  );

  const isNominalValue = useMemo(
    () => transaction.categoryId === TransactionCategory.ChangeNominalValue,
    [transaction]
  );

  const isDeletion = useMemo(() => transaction.categoryId === TransactionCategory.Deletion, [transaction]);

  const allowedToRollbackOrEditTransaction = useMemo(() => {
    if (isPending) {
      return true;
    }

    if (!transaction.canEditConfirmed) {
      return false;
    }

    if (isIssueOrBuySell) {
      if (
        transaction.transactionTypeId !== TransactionTypes.RsaSharesIssued &&
        transaction.transactionTypeId !== TransactionTypes.StandAloneRsa
      ) {
        return true;
      }
    }
  }, [isIssueOrBuySell, isPending, transaction.canEditConfirmed, transaction.transactionTypeId]);

  const dropdownItems = useMemo(
    () => [
      {
        key: "edit",
        label: t("transactionMenu.edit"),
        icon: <EditIcon />,
        onClick: hasFullAccess ? handleEditClick : undefined,
        isDisabled: !hasFullAccess,
      },
      ...(isPending
        ? [
            {
              key: "delete",
              label: <Ui.m style={{ color: scssVariables.critical500 }}>{t("transactionMenu.delete")}</Ui.m>,
              icon: <DeleteIcon color={scssVariables.critical500} />,
              onClick: hasFullAccess ? handleDeleteClick : undefined,
              isDisabled: !hasFullAccess,
            },
          ]
        : [
            {
              key: "rollback",
              label: <Ui.m style={{ color: scssVariables.critical500 }}>{t("transactionMenu.rollback")}</Ui.m>,
              icon: <ResetIcon color={scssVariables.critical500} />,
              onClick: hasFullAccess ? handleRollbackClick : undefined,
              isDisabled: !hasFullAccess,
            },
          ]),
    ],
    [handleDeleteClick, handleEditClick, handleRollbackClick, hasFullAccess, isPending]
  );

  return (
    <div
      className="d-flex w-100 justify-content-between align-items-center py-2 cursor-pointer"
      onClick={decoratedOnClick}
    >
      {isIssueOrBuySell && (
        <IssueSharesAndBuySellHeader transaction={transaction} isPortfolio={portfolioView} isMini={isMini} />
      )}
      {isSplit && <SplitHeader transaction={transaction} />}
      {isBundle && <CapitalIncreaseHeader transaction={transaction} />}
      {isNominalValue && <NominalValueItemHead transaction={transaction} />}
      {isDeletion && <DeletionHeader transaction={transaction} />}

      <div className="d-flex align-items-center text-low">
        {!portfolioView && allowedToRollbackOrEditTransaction && (
          <div onClick={(e) => e.stopPropagation()}>
            {isPending && (
              <Button
                className="me-3"
                size="s"
                variant="secondary"
                onClick={hasFullAccess && isConfirmAllowed ? handleConfirmClick : undefined}
                isDisabled={!hasFullAccess || !isConfirmAllowed}
              >
                {t("transactionMenu.confirm")}
              </Button>
            )}
            <ContextMenu items={dropdownItems} className="me-3">
              <Button isOnlyIcon variant="secondary" data-testid="plan-dropdown-btn" size="s">
                <MenuTabBarVerticalIcon />
              </Button>
            </ContextMenu>
          </div>
        )}

        <ProgrammaticAccordionToggle eventKey={eventKey} itemIdToOpen={itemIdToOpen} />
      </div>
    </div>
  );
};

export default Header;
