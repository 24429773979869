import React, { FC } from "react";
import cn from "classnames";
import { defaultTo, equals } from "ramda";

import { createTranslation, TranslationNS } from "translation";

import { EquityIcon, PieChartIcon, TransactionsIcon, UsersIcon } from "../../../../icons/svg";
import { Ui } from "../../../atoms";
import { StakeholderDetailsContext } from "../stakeholder-details.context";
import classes from "../stakeholder-details.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.tabs");

export enum ShareholderSelectedTabOptions {
  "ownership" = "ownership",
  "instruments" = "instruments",
  "transactions" = "transactions",
  "contacts" = "contacts",
}

const Tabs: FC = () => {
  const details = StakeholderDetailsContext.useStoreState((state) => state.details);
  const selectedTab = StakeholderDetailsContext.useStoreState((state) => state.selectedTab);
  const setSelectedTab = StakeholderDetailsContext.useStoreActions((actions) => actions.setSelectedTab);

  return (
    <div
      className={cn(classes["tabs-head"], {
        [classes["ownership"]]: equals(selectedTab, ShareholderSelectedTabOptions.ownership),
        [classes["instruments"]]: equals(selectedTab, ShareholderSelectedTabOptions.instruments),
        [classes["transactions"]]: equals(selectedTab, ShareholderSelectedTabOptions.transactions),
        [classes["contacts"]]: equals(selectedTab, ShareholderSelectedTabOptions.contacts),
      })}
    >
      <div
        className={cn(classes["tab-item"], {
          [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.ownership),
        })}
        onClick={() => {
          setSelectedTab(ShareholderSelectedTabOptions.ownership);
        }}
      >
        <PieChartIcon />
        <Ui.s className="ms-1">{t("ownership")}</Ui.s>
      </div>

      <div
        className={cn(classes["tab-item"], {
          [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.instruments),
        })}
        onClick={() => {
          setSelectedTab(ShareholderSelectedTabOptions.instruments);
        }}
      >
        <EquityIcon />
        <Ui.s className="ms-1">{t("instruments")}</Ui.s>

        <Ui.s
          className={cn(classes["badge"], {
            [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.instruments),
          })}
        >
          {defaultTo([], details?.financialInstruments).length}
        </Ui.s>
      </div>

      <div
        className={cn(classes["tab-item"], {
          [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.transactions),
        })}
        onClick={() => {
          setSelectedTab(ShareholderSelectedTabOptions.transactions);
        }}
      >
        <TransactionsIcon />
        <Ui.s className="ms-1">{t("transactions")}</Ui.s>

        <Ui.s
          className={cn(classes["badge"], {
            [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.transactions),
          })}
        >
          {defaultTo([], details?.transactions).length}
        </Ui.s>
      </div>

      {details?.isCompanyOwned ? (
        <div
          className={cn(classes["tab-item"], {
            [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.contacts),
          })}
          onClick={() => {
            setSelectedTab(ShareholderSelectedTabOptions.contacts);
          }}
        >
          <UsersIcon />
          <Ui.s className="ms-1">Contacts</Ui.s>

          <Ui.s
            className={cn(classes["badge"], {
              [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.contacts),
            })}
          >
            {defaultTo([], details?.contacts).length}
          </Ui.s>
        </div>
      ) : null}
    </div>
  );
};

export default Tabs;
