import React, { FC, ReactNode, useCallback, useEffect } from "react";
import axios from "axios";
import { isNil } from "ramda";

import { Button, ModalInfo, Spinner } from "common/components/atoms";
import { CloseIcon } from "common/icons/svg";

import Content from "./components/Content";
import Details from "./components/Details";
import Tabs from "./components/Tabs";
import { StakeholderDetailsContext } from "./stakeholder-details.context";
import classes from "./stakeholder-details.module.scss";
import { CapTableStakeholderDetailsType } from "./types";

type Props = FC<{
  children?: ReactNode;
  show: boolean;
  getDataLink: string;

  // this callback should have correct api request to get necessary data and handle close action
  handleClose: () => void;
}>;

type ShareholderDetailsProps = Props & {
  Details: typeof Details;
  Tabs: typeof Tabs;
  Content: typeof Content;
};

const Children: Props = ({ children, show, getDataLink, handleClose }) => {
  const details = StakeholderDetailsContext.useStoreState((state) => state.details);
  const isLoading = StakeholderDetailsContext.useStoreState((state) => state.isLoading);
  const setDetails = StakeholderDetailsContext.useStoreActions((actions) => actions.setDetails);
  const setIsLoading = StakeholderDetailsContext.useStoreActions((actions) => actions.setIsLoading);
  const setCloseFunction = StakeholderDetailsContext.useStoreActions((actions) => actions.setCloseFunction);
  const setGetDataFunction = StakeholderDetailsContext.useStoreActions((actions) => actions.setGetDataFunction);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const request = await axios.get<CapTableStakeholderDetailsType>(getDataLink);

      if (request.status === 200) {
        setDetails(request.data);
      }
    } catch (e) {
      console.warn({ e });
    } finally {
      setIsLoading(false);
    }
  }, [getDataLink, setDetails, setIsLoading]);

  const closeHandler = useCallback(() => {
    handleClose();
    setDetails(null);
  }, [handleClose, setDetails]);

  useEffect(() => {
    if (show) {
      getData();
    }

    // stakeholderId maybe need this prop to handle modal view correctly
  }, [getData, show]);

  useEffect(() => {
    setCloseFunction(closeHandler);
  }, [closeHandler, setCloseFunction]);

  useEffect(() => {
    setGetDataFunction(getData);
  }, [getData, setGetDataFunction]);

  if (isLoading || (isNil(details) && show)) {
    return <Spinner />;
  }

  return (
    <ModalInfo show={show} handleClose={closeHandler} className={classes.modal} onHide={closeHandler}>
      {children}

      <Button variant="dark" size="s" className={classes["close-btn"]} isOnlyIcon onClick={closeHandler}>
        <CloseIcon fontSize={24} />
      </Button>
    </ModalInfo>
  );
};

const StakeholderDetails: ShareholderDetailsProps = (props) => {
  return (
    <StakeholderDetailsContext.Provider>
      <Children {...props} />
    </StakeholderDetailsContext.Provider>
  );
};

StakeholderDetails.Details = Details;
StakeholderDetails.Content = Content;
StakeholderDetails.Tabs = Tabs;

export default StakeholderDetails;
