import { FC, ReactNode } from "react";
import cn from "classnames";

import { H, P } from "common/components/atoms";
import LinkHelpText from "common/components/atoms/LinkHelpText/LinkHelpText";
import { Ui } from "common/components/atoms/Typography";
import ReadMore from "common/components/molecules/ReadMore/ReadMore";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./agreement-summary.module.scss";
import PostTerminationClause from "./post-termination-clause";

export type Item = {
  title?: string | ReactNode | JSX.Element;
  description: string;
  content: string;
};

type PropsTypes = {
  agreementItems: Item[];
  postTerminationClauseItems?: Item[] | null;
  essentialTerms?: string;
};

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

const AgreementSummary: FC<PropsTypes> = ({ agreementItems, postTerminationClauseItems, essentialTerms }) => {
  return (
    <>
      <div className={classes["content"]}>
        {agreementItems.map((item, index) => (
          <div className={cn(classes["single"], "text-truncate")} key={index}>
            <div className="d-flex flex-column">
              <Ui.m className="text-truncate">{item.title || "-"}</Ui.m>
              <Ui.xs style={{ color: scssVariables.foregroundLow }}>{item.description}</Ui.xs>
            </div>

            <LinkHelpText title={t("infoWidget.title", { infoTitle: item.title })} content={item.content}>
              <QuestionCircleIcon className={classes["question"]} />
            </LinkHelpText>
          </div>
        ))}
      </div>

      {postTerminationClauseItems && postTerminationClauseItems.length > 0 && (
        <PostTerminationClause terminationItems={postTerminationClauseItems} />
      )}

      {essentialTerms && (
        <div className="mt-3">
          <H.xxxs className="mb-1">Essential terms from agreement</H.xxxs>
          <ReadMore description={essentialTerms} modalTitle="Essential terms">
            <P.s style={{ whiteSpace: "break-spaces" }}>{essentialTerms}</P.s>
          </ReadMore>
        </div>
      )}
    </>
  );
};

export default AgreementSummary;
