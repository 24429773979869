import { useMemo } from "react";
import { format } from "date-fns";

import { VestingTypesEnum } from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { Item } from "pages/receiving/receive-plan/common/agreement-summary/agreement-summary";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.receivePlan");
const tCommon = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

export type RSAPlanDetails = {
  totalShares: number;
  purchasePricePerShare: number;
  pricePerShare: number;
  shareMarketValue: number;
  vestingCliff: number;
  vestingPeriod: number;
  shareClassName: string;
  allowAcceleratedVesting: boolean;
  vestingStartsAt: string;
  vestingEndsAt: string;
  vestingType: string;
  vestingTypeId: number;
  vestingMilestones?: any[];
  currencyId: number;
  essentialTerms?: string;
};

const useRSAAgreementDetails = (planDetails: RSAPlanDetails | null) => {
  const { currencySymbol } = useCurrencyById(planDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const agreementItems: Item[] = useMemo(
    () => [
      {
        description: tCommon("agreementType"),
        content: tCommon("infoWidget.RSA.agreementTypeRSA"),
        title: "Restricted stock awards",
      },
      {
        description: tCommon("RSA.numberOfShares"),
        content: tCommon("infoWidget.RSA.numberOfShares"),
        title: fNumber(planDetails?.totalShares, "amount"),
      },
      {
        description: tCommon("RSA.purchasePricePerShare"),
        content: tCommon("infoWidget.RSA.purchasePricePerShare"),
        title: fNumber(planDetails?.purchasePricePerShare, "sharePrice"),
      },
      {
        description: tCommon("pricePerShare"),
        content: tCommon("infoWidget.pricePerShare"),
        title: fNumber(planDetails?.pricePerShare, "sharePrice"),
      },
      {
        description: tCommon("RSA.shareMarketValue"),
        content: tCommon("infoWidget.RSA.shareMarketValue"),
        title: fNumber(planDetails?.shareMarketValue, "value"),
      },
      {
        description: tCommon("cliff"),
        content: tCommon("infoWidget.cliff"),
        title: tCommon("cliffValue", { count: planDetails?.vestingCliff }),
      },
      {
        description: tCommon("vestingPeriod"),
        content: tCommon("infoWidget.vestingPeriod"),
        title: planDetails?.vestingPeriod ? tCommon("vestingPeriodValue", { count: planDetails?.vestingPeriod }) : "-",
      },
      {
        description: tCommon("shareClass"),
        content: tCommon("infoWidget.shareClass"),
        title: planDetails?.shareClassName || "-",
      },
      {
        description: tCommon("acceleratedVesting"),
        content: planDetails?.allowAcceleratedVesting
          ? tCommon("infoWidget.withAcceleratedVesting")
          : tCommon("infoWidget.withoutAcceleratedVesting"),
        title: planDetails?.allowAcceleratedVesting ? t("yes") : t("no"),
      },
      {
        description: tCommon("vestingStartDate"),
        content: tCommon("infoWidget.vestingStartDate"),
        title: planDetails?.vestingStartsAt ? format(new Date(planDetails?.vestingStartsAt), "d MMMM yyyy") : "-",
      },
      {
        description: tCommon("vestingEndDate"),
        content: tCommon("infoWidget.vestingEndDate"),
        title: planDetails?.vestingEndsAt ? format(new Date(planDetails?.vestingEndsAt), "d MMMM yyyy") : "-",
      },
      {
        description: tCommon("RSA.vestingType"),
        content: tCommon("infoWidget.RSA.vestingType"),
        title: planDetails?.vestingType || "-",
      },
    ],
    [
      fNumber,
      planDetails?.allowAcceleratedVesting,
      planDetails?.pricePerShare,
      planDetails?.purchasePricePerShare,
      planDetails?.shareClassName,
      planDetails?.shareMarketValue,
      planDetails?.totalShares,
      planDetails?.vestingCliff,
      planDetails?.vestingEndsAt,
      planDetails?.vestingPeriod,
      planDetails?.vestingStartsAt,
      planDetails?.vestingType,
    ]
  );

  const isShowScheduleChart = useMemo(
    () =>
      planDetails?.vestingTypeId === VestingTypesEnum.TIME_VESTING ||
      planDetails?.vestingTypeId === VestingTypesEnum.COMBINE_VESTING,
    [planDetails]
  );

  const isShowMilestones = useMemo(
    () =>
      (planDetails?.vestingTypeId === VestingTypesEnum.COMBINE_VESTING ||
        planDetails?.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING) &&
      planDetails?.vestingMilestones?.length,
    [planDetails]
  );

  return { agreementItems, isShowScheduleChart, isShowMilestones };
};

export default useRSAAgreementDetails;
