import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import cn from "classnames";
import { add, Duration } from "date-fns";
import { useFormikContext } from "formik";
import * as R from "ramda";

import { DatePicker, H, Helper, TextField, Ui } from "common/components/atoms";
import PeriodPickerFormik from "common/components/atoms/PeriodPickerFormik/PeriodPickerFormik";
import SigningManagerField from "common/components/molecules/signing-manager/SigningManagerField";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { newPlanFieldsNames as f, periodVariants } from "common/plan/planFormFields";
import PoolShareClassField from "common/pool/components/PoolForm/PoolShareClassField/PoolShareClassField";
import { ShareClassServiceCompanyShareClassesResponse } from "common/shareClass/ShareClassService";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import EssentialTermsField from "../../../../create-plan/fields/EssentialTermsField";
import { PlanForm } from "../../../types";
import classes from "./OneOffStockOptions.module.scss";

type PropsType = {
  shareClass?: string;
  planType?: number;
  hideShareDetails?: boolean;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const OneOffStockOption: FC<PropsType> = ({ hideShareDetails = false }) => {
  const { currencySymbol } = useCurrency();
  const fNumber = useFormatNumbers();

  const [shareClasses, setShareClasses] = useState<ShareClassServiceCompanyShareClassesResponse>([]);
  const [currentSharePrice, setCurrentSharePrice] = useState<number>(0);

  const companyId = useStoreState((state) => state.activeCompanyModel?.companyId);

  const instrumentTypes = useStoreState((state) => state.common.dropdowns?.instrumentTypes);

  const { values, handleChange, handleBlur, setFieldValue, touched, errors } = useFormikContext<PlanForm>();

  const instrumentTypeName = useMemo(
    () => instrumentTypes?.find((type) => type.id === values?.planTypeId)?.name,
    [instrumentTypes, values?.planTypeId]
  );

  const shareClassName = useMemo(() => {
    return shareClasses?.find((type) => type.id === values?.shareClassId)?.name;
  }, [shareClasses, values?.shareClassId]);

  const expirationDate = useMemo(() => {
    const addParameter: Duration = {};

    if (values.expirationDate?.periodType === "Year") {
      addParameter.years = values.expirationDate?.amount || 0;
    } else if (values.expirationDate?.periodType === "Month") {
      addParameter.months = values.expirationDate?.amount || 0;
    } else {
      addParameter.days = values?.expirationDate?.amount || 0;
    }

    return transformDateToCommonDateFormat(add(new Date(), addParameter));
  }, [values.expirationDate]);

  const infoNumberShares = useMemo(
    () =>
      values.numberOfShares
        ? t("pricePerShare", {
            price: fNumber(values.numberOfShares * R.defaultTo(0, values.exercisePrice), "value"),
          })
        : undefined,
    [values.numberOfShares, values.exercisePrice, fNumber]
  );

  const handleBoardApprovalDateChange = useCallback(
    (date: Date | string) => {
      setFieldValue("approvedAt", date);
    },
    [setFieldValue]
  );

  const handleGrantDateChange = useCallback(
    (date: Date | string) => {
      setFieldValue(f.grantedAt, date);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (companyId) {
      axios
        .get(`/api/company/share-price/${companyId}`)
        .then((res) => {
          setCurrentSharePrice(res.data.sharePrice);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [companyId]);

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <TextField
            type="number"
            label={t("optionsAmount")}
            value={values.numberOfShares}
            isTouched={touched.numberOfShares}
            error={errors.numberOfShares}
            name={f.numberOfShares}
            onBlur={handleBlur}
            onChange={handleChange}
            info={infoNumberShares}
          />
        </Col>
        <Col>
          <TextField
            type="number"
            label={t("optionsPrice")}
            value={values.purchasePrice}
            isTouched={touched.purchasePrice}
            error={errors.purchasePrice}
            name={f.purchasePrice}
            onBlur={handleBlur}
            onChange={handleChange}
            iconRight={currencySymbol}
          />
        </Col>
      </Row>

      <Row className="my-4" style={{ display: !values.poolId ? "" : "none" }}>
        <Col>
          <PoolShareClassField getShareClassesCallback={setShareClasses} />
        </Col>
        <Col />
      </Row>

      <Row className="mb-4">
        <Col>
          <TextField
            type="number"
            label={t("exercisePrice")}
            value={values.exercisePrice}
            isTouched={touched.exercisePrice}
            error={errors.exercisePrice}
            name={f.exercisePrice}
            onBlur={handleBlur}
            onChange={handleChange}
            iconRight={currencySymbol}
          />
        </Col>
        <Col>
          <div className="mt-5">{t("currentSharePrice", { price: fNumber(currentSharePrice, "sharePrice") })}</div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <PeriodPickerFormik
            label={t("expirationDate")}
            periodVariants={periodVariants}
            /*hint={{
              title: t("exercisePeriod"),
              description: t("exercisePeriodDescription"),
            }}*/
            nameAmount={f.optionsExpiration}
            namePeriod={f.optionsExpirationTimeUnit}
          />
        </Col>
        <Col />
      </Row>

      <Row className="mt-5 mb-3">
        <Helper>
          <Col xs={6}>
            <Helper.Question questionId="signing-manager" type="input">
              <SigningManagerField
                label={t("signingManager")}
                error={errors.managerId}
                isTouched={touched.managerId}
                selectedId={values.managerId}
                onChange={(id) => {
                  setFieldValue("managerId", id);
                }}
              />
            </Helper.Question>
          </Col>
          <Helper.Answer className="mt-1" answerId="signing-manager" text={t("signingManagerAnswer")} />
        </Helper>
      </Row>

      {!hideShareDetails && (
        <div className={cn(classes["neutral-plate"], "mt-3")}>
          <span className={classes["share-details-title"]}>{t("shareDetailsOneOffDescriptionTitle")}</span>
          <div className="d-flex justify-content-between pt-2">
            <div>
              <Ui.m>
                {t("shareClass")}: <strong>{shareClassName}</strong>
              </Ui.m>
              <Ui.m className="my-2">
                {t("type")} <strong>{instrumentTypeName}</strong>
              </Ui.m>
              <Ui.m>
                {t("expirationDate")}: <strong>{expirationDate}</strong>
              </Ui.m>
            </div>
          </div>
        </div>
      )}

      <div className={cn("mt-5 pt-5", classes["approval-info"])}>
        <H.xxs className="mb-3">{t("approvalInfo")}</H.xxs>

        <Row className="mb-4">
          <Col>
            <DatePicker
              isDateOnlyString
              label={t("boardApproval")}
              date={values.approvedAt}
              onChange={handleBoardApprovalDateChange}
            />
          </Col>
          <Col>
            <DatePicker
              isDateOnlyString
              label={t("grantDate")}
              date={values.grantedAt}
              onChange={handleGrantDateChange}
            />
          </Col>
        </Row>

        <Helper>
          <EssentialTermsField className="mt-4" />
        </Helper>
      </div>
    </div>
  );
};

export default OneOffStockOption;
