import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { pluck } from "ramda";

import { createTranslation, Languages, TranslationNS } from "translation";

import { Dropdown } from "../../atoms";
import classes from "./style.module.scss";

const t = createTranslation(TranslationNS.common, "molecules.languageSelector");

const LanguageSelector: FC<JSX.IntrinsicElements["div"]> = ({ ...props }) => {
  const { i18n } = useTranslation();

  const languages = useMemo(() => {
    return {
      [Languages.en]: {
        title: t("en"),
        lng: Languages.en,
      },
      [Languages.no]: {
        title: t("no"),
        lng: Languages.no,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div {...props}>
      <Dropdown
        className={classes["container"]}
        selectedValue={languages[i18n.language as Languages].title}
        options={pluck("title", Object.values(languages))}
        onChange={(lng: string) => {
          const selectedLng = Object.values(languages).find((el) => el.title === lng);
          i18n.changeLanguage(selectedLng?.lng);
        }}
      />
    </div>
  );
};

export default LanguageSelector;
